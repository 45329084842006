<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="远控客户端名称" prop="monitorname">
              <a-input v-model.trim="queryParams.monitorname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="远控客户端编号" prop="monitornum">
              <a-input v-model.trim="queryParams.monitornum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="项目地址" prop="address">
              <a-input v-model.trim="queryParams.address" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">远控客户端列表</h3>
          <div class="table-btns">
<!--            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="monitorinfoid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="isremote" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'支持远控':'禁用远控'}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button-group><a-button @click="handleRemote(value,false)">关闭</a-button><a-button @click="handleRemote(value,true)">开启</a-button></a-button-group>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>
<script>
import pagination from '@/mixins/pagination'
import { getItemFromArrayByKey } from 'U'
import {
  deleteMonitorinfo,
  getMonitorinfoListByCondition,
  modifyMonitorinfo,
  stopClients,
  stopRemote
} from "A/monitoring";
import ParamsConfig from "V/monitorDevice/monitoring/deviceList/params/paramsConfig";
import {mapState} from "vuex";
export default {
  name:'remoteEnable',
  mixins: [pagination],
  components: {
    ParamsConfig
  },
  data() {
    return {
      checked:'',
      paramsModalVisible:false,
      showAdvanced: false,
      areaLists: [],
      queryParams: {
        areacode:'',
        monitorname: '',
        monitornum: '',
        address: '',
      },
      remoteEnable:'',
      tableColumns: [
        {
          title: '被控端名称',
          dataIndex: 'monitorname',
          key: 'monitorname',
          ellipsis: true,
        },
        {
          title: '被控端ID',
          dataIndex: 'monitornum',
          key: 'monitornum',
          ellipsis: true,
          width: 165
        },
        {
          title: '版本号',
          dataIndex: 'version',
          key: 'version',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' },
          width: 60
        },
        {
          title: '使用项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '项目地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '远程使能',
          dataIndex: 'isremote',
          key: 'isremote',
          ellipsis: true,
          align: "center",
          scopedSlots: { customRender: 'isremote' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 180,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    pageTitle() {
      return '远控使能';
    },
    breadcrumb() {
      return [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '高级设置',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMonitorinfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.monitorinfoid);
      }).catch(()=>{
      });
    },
    delete(monitorinfoid) {
      this.showLoading();
      if(monitorinfoid) {
        let params = {
          monitorinfoid
        };
        deleteMonitorinfo(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    paramsSet(type, monitorinfoid, record){
      this.monitornum = record.monitornum;
      this.$router.push({
        name: 'paramsMonitor',
        params: {
          prePages: [
            {
              name: '网络管理',
              path: ''
            },
            {
              name: '监控软终端',
              path: ''
            },
            {
              name: '设备列表',
              path: 'deviceList'
            },
          ],
          monitornum: record.monitornum,
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let monitorinfoid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'monitorinfoid', monitorinfoid);
      if(type == 'delete') {
        this.deleteConfirm(monitorinfoid, record)
      }else if(type == 'reboot'){
        stopClients({monitornum:record.monitornum})
        stopRemote({monitornum:record.monitornum})
      }else if(type == 'params'){
        this.paramsSet(type, monitorinfoid, record)
      }else{
        this.showModal(type, monitorinfoid, record)
      }
    },
    handleRemote(value,flag){
      let params={
        monitornum:value.monitornum,
        isremote:flag?1:0,
      }
      modifyMonitorinfo(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
          this.getTableData()
        }
      })
    }
  }
}
</script>